<template lang="pug">
aside.informer-clients
  .iinformer-clients__cont.cont
    h2.informer-clients__title.title Корпоративным клиентам
    p.informer-clients__text.text Сделаем под заказ набор из любого шоколада, в зависимости от ваших личных требований
    router-link.informer-clients__link.link-more(:to="{ name: 'OnOrder' }") Сотрудничество
</template>

<script>
export default {
  name: 'InformerClients'
}
</script>

<style lang="sass">
@import 'src/assets/styles/components/informer-clients'
</style>
