<template lang='pug'>
.modal-adult
  .modal-adult_inner
    .modal-adult__layer
    .modal-adult__cont
      h2.modal-adult__title.title Вам исполнилось
        span.modal-adult__title-color
          | 18 лет?
      .modal-adult__box
        button.modal-adult__button.modal-adult__button_agree.button-white.text-big(type="button", @click="setAdultAge") Да, мне есть 18
        button.modal-adult__button.modal-adult__button_disagree.button-dark.text-big(type="button", @click="setMinorAge") Нет
</template>

<script>
import '@/assets/styles/components/modal-adult.sass'
import { setAdultUser } from '../../assets/scripts/API'

export default {
  name: 'TheModalAdult',
  emits: ['close-modal', 'set-adult-user'],
  methods: {
    setAdultAge () {
      this.$emit('close-modal')
      this.$emit('set-adult-user')
      setAdultUser()
    },
    setMinorAge () {
      this.$emit('close-modal')
      this.$router.push({ name: 'Catalog' })
    }
  }
}
</script>
