<template lang="pug">
head-meta(:title-page="titlePage", :description="descriptionPage" )
template(v-if="!isNotFound")
  catalog-head(:caption="caption")
  category-info(:description="description")
  tile-line(:products="products")
  the-modal-adult(v-if="isShowAdultModal", @close-modal="closeAdultModal", @set-adult-user= "setAdultUser")
  informer-order(class-mod="catalog")
  informer-clients
  schema-org-category(v-if="category", :category="category")
  schema-org-crumbs
the-error(v-else, text="Такой категории не существует", :route="{ name: 'Catalog', text: 'В каталог' }" )
contacts-line
</template>

<script>
import { getProductsCategory } from '../../assets/scripts/API'
import TileLine from '../../components/_tiles/TileLine'
import CategoryInfo from '../../components/catalog/CategoryInfo'
import CatalogHead from '../../components/catalog/CatalogHead'
import HeadMeta from '../../components/_layout/HeadMeta'
import TheModalAdult from '../../components/modals/TheModalAdult'
import Storage from '../../assets/scripts/Storage'
import ContactsLine from '../../components/_layout/TheContactsLine'
import TheError from '../../components/error/TheError'
import InformerClients from '../../components/_informers/InformerClients'
import InformerOrder from '../../components/_informers/InformerOrder'
import SchemaOrgCategory from '../../components/catalog/SchemaOrgCategory'
import SchemaOrgCrumbs from '../../components/_tools/SchemaOrgCrumbs'

export default {
  name: 'CatalogCategoryPage',
  components: { TheError, ContactsLine, TheModalAdult, HeadMeta, CatalogHead, CategoryInfo, TileLine, InformerClients, InformerOrder, SchemaOrgCategory, SchemaOrgCrumbs },
  data () {
    return {
      caption: 'Каталог шоколада',
      description: '',
      products: [],
      isShowAdultModal: false,
      isAdultCategory: 0,
      isNotFound: false,
      category: null
    }
  },
  computed: {
    titlePage () {
      return this.isNotFound ? 'Страница не найдена' : `${this.caption} — собственное производство Музея «Шоколадный Выборг»`
    },
    descriptionPage () {
      return this.isNotFound ? '' : `Купить ${this.caption} ручной работы. Корпоративные подарки, индивидуальные конфеты под заказ, необычный шоколад. Приходите пробовать шоколад!`
    },
    lowPrice () {
      return this.products.reduce((min, product) => {
        if (product.price_current && product.price_current < min) {
          return product.price_current
        }
        return min
      }, this.products[0].price_current)
    },
    highPrice () {
      return this.products.reduce((max, product) => {
        if (product.price_current && product.price_current > max) {
          return product.price_current
        }
        return max
      }, this.products[0].price_current)
    }
  },
  methods: {
    async getDataPage (slug) {
      const data = await getProductsCategory(slug)
      if (data.status === 'error') return await this.$router.push({ name: 'Error' })
      this.caption = data.category_name
      this.description = data.category_description
      this.products = data.products
      this.isAdultCategory = data.category_is_adult
      this.isShowAdultModal = !!(this.isAdultCategory && !this.isAdultUser)
      this.category = {
        name: data.category_name,
        image: data.products[0].image.j564,
        offerCount: data.products_count,
        lowPrice: this.lowPrice,
        highPrice: this.highPrice
      }
    },
    setAdultUser () {
      this.isAdultUser = true
    },
    closeAdultModal () {
      this.isShowAdultModal = false
    }
  },
  mounted () {
    this.user = Storage.user
    this.isAdultUser = !!(this.user && this.user.is_adult)
    this.getDataPage(this.$route.params.slug)
  },
  async beforeRouteUpdate (to, from, next) {
    await this.getDataPage(to.params.slug)
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Error') {
      this.isNotFound = true
      next(false)
    }
    next()
  }
}
</script>
