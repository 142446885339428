<template lang="pug">
article.error
  .error__cont.cont
    h1.error__caption.caption Ошибка 404
    p.error__text.text-big {{text}}
    .error__btn-box
      router-link.error__button.link-more(:to="{ name: route.name }") {{route.text}}
</template>

<script>
import '@/assets/styles/components/error.sass'

export default {
  name: 'TheError',
  props: {
    text: {
      type: String,
      default: 'Такой страницы пока не существует'
    },
    route: {
      type: Object,
      default () {
        return { name: 'Home', text: 'На главную' }
      }
    }
  }
}
</script>
