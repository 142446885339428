<template lang="pug">
teleport(to="body")
  component(:is="'script'", type="application/ld+json", v-html="jsonld")
</template>

<script>
import { url } from '../../assets/scripts/variables'

export default {
  name: 'SchemaOrgCrumbs',
  props: {
    slug: String,
    linkName: String
  },
  data () {
    return {
      url
    }
  },
  computed: {
    jsonld () {
      const itemListElement = [
        {
          '@type': 'ListItem',
          position: 0,
          item: {
            '@id': this.url,
            name: 'choco-vbg.ru'
          }
        },
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': `${this.url}/catalog`,
            name: 'Каталог'
          }
        }
      ]
      if (this.slug && this.linkName) {
        itemListElement.push({
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': `${this.url}/catalog/${this.slug}`,
            name: this.linkName
          }
        })
      }
      return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: itemListElement
      })
    }
  }
}
</script>
