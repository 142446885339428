<template lang="pug">
teleport(to="body", v-if="category")
  component(:is="'script'", type="application/ld+json", v-html="jsonld")
</template>

<script>
import { url } from '../../assets/scripts/variables'

export default {
  name: 'SchemaOrgCategory',
  props: {
    category: Object
  },
  data () {
    return {
      url
    }
  },
  computed: {
    categoryName () {
      return this.category.name ? this.category.name : ''
    },
    categoryImage () {
      return this.category.image ? this.category.image : ''
    },
    categoryURL () {
      return `${this.url}${this.$route.path}`
    },
    categoryLowPrice () {
      return this.category.lowPrice ? this.category.lowPrice : '0'
    },
    categoryHighPrice () {
      return this.category.highPrice ? this.category.highPrice : '0'
    },
    categoryOfferCount () {
      return this.category.offerCount ? this.category.offerCount : '0'
    },
    jsonld () {
      return JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: this.categoryName,
        image: this.categoryImage,
        offers: {
          '@type': 'AggregateOffer',
          url: this.categoryURL,
          priceCurrency: 'RUB',
          lowPrice: this.categoryLowPrice,
          highPrice: this.categoryHighPrice,
          offerCount: this.categoryOfferCount
        }
      })
    }
  }
}
</script>
