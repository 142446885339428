<template lang="pug">
aside.catalog-head(:class="classMod ? `catalog-head_${classMod}` : ''")
  .catalog-head__cont.cont
    h1.catalog-head__caption.caption {{ caption }}
    ul.catalog-head__list
      li.catalog-head__item
        router-link.catalog-head__button(
            :to="{ name: 'Catalog' }",
            exact-active-class="catalog-head__button_active"
          ) Весь ассортимент
      li.catalog-head__item(v-for="(link, i) in categories", :key="i")
        router-link.catalog-head__button(
            :to="{ name: 'Category', params: { slug: link.slug, is_adult_category: link.is_adult } }",
            exact-active-class="catalog-head__button_active"
          ) {{ link.name }}
</template>

<script>
import '@/assets/styles/components/catalog-head.sass'
import { getCategories } from '../../assets/scripts/API'

export default {
  props: {
    caption: {
      type: String,
      default: 'Каталог шоколада'
    }
  },
  name: 'CatalogHead',
  data () {
    return {
      classMod: false,
      categories: []
    }
  },
  mounted () {
    getCategories().then(data => { this.categories = data })
  }
}
</script>
