<template lang='pug'>
.category-info
  .category-info__cont.cont
    p.category-info__description {{ description }}
</template>

<script>
import '@/assets/styles/components/category-info.sass'

export default {
  name: 'CategoryInfo',
  props: {
    description: String
  }
}
</script>
